import React from 'react'
import { RichTextJson } from 'types'
import Image from '@partials/Image'
import '@sass/components/about/WhoAmI.scss'
import { renderRichText } from '@helpers/richText'

type Props = {
  data: {
    text: {
      first: string
      second: RichTextJson
    }
    image: string
  }
}

const WhoAmI: React.FC<Props> = ({ data }) => {
  return (
    <div className="column is-12 whoami">
      <div className="column is-12 is-paddingless">
        <h2 className="section-title has-after has-after--bottom wow fadeIn" data-wow-duration="1.5s">
          Who am I
        </h2>
      </div>
      <div className="columns is-multiline">
        <div className="column is-6 whoami__left p-mb-0">
          <Image fadeType="fadeIn" animate src={data.image} alt="laptop" wowDuration={1.5} />
        </div>
        <div className="column is-6 whoami__right p-mb-0">
          <p className="wow fadeIn" data-wow-delay="0.25s" data-wow-duration="1.5s">
            {data.text.first}
          </p>
          <div className="wow fadeIn" data-wow-delay="0.5s" data-wow-duration="1.5s">
            {renderRichText(data.text.second)}
          </div>
        </div>
      </div>
    </div>
  )
}
export default WhoAmI
