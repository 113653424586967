import React from 'react'
import { SkillObject } from 'types'
import Image from '@partials/Image'
import '@sass/components/about/Capabilities.scss'

type Props = {
  data: {
    text: string
    image: string
    skills: SkillObject[]
  }
}

const Capabilities: React.FC<Props> = ({ data }) => (
  <div className="capabilities columns is-multiline">
    <div className="column is-12 is-paddingless wow fadeIn" data-wow-duration="1.5s">
      <h2 className="section-title has-after has-after--bottom">Capabilities</h2>
    </div>
    <div className="column is-6 skills-container p-mb-0">
      {data.skills.map(({ id, title, image }, index) => (
        <p key={id} className="skill-wrapper wow fadeIn" data-wow-delay={`${0.05 * index}s`} data-wow-duration="1.5s">
          <Image src={image.url} alt={image.alt} />
          <span>{title}</span>
        </p>
      ))}
    </div>
    <div className="column is-6 is-paddingless info-wrapper">
      <p className="wow fadeIn" data-wow-delay="0.25s" data-wow-duration="1.5s">
        {data.text}
      </p>
      <Image src={data.image} alt="laptop" animate fadeType="fadeIn" wowDelay={0.35} wowDuration={1.5} />
    </div>
  </div>
)

export default Capabilities
