import React from 'react'
import { INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents, Options as RichTextOptions } from '@contentful/rich-text-react-renderer'
import { RichTextJson } from 'types'

export const renderRichText = (content: RichTextJson) => {
  const options: RichTextOptions = {
    renderNode: {
      [INLINES.HYPERLINK]: function Link({ data }, child) {
        return (
          <a target="_blank" rel="noopener noreferrer" href={data.uri}>
            {child}
          </a>
        )
      },
    },
  }

  return documentToReactComponents(JSON.parse(content.raw), options)
}
