import React from 'react'
import { useCtaData } from '@hooks/useCtaData'
import Button from '@partials/Button'
import Image from '@partials/Image'
import '@sass/components/Cta.scss'

const Cta: React.FC = () => {
  const { title, text, button, image } = useCtaData()

  return (
    <div className="wow fadeIn columns is-multiline cta h-mb-auto p-mb-0" data-wow-duration="1.5s">
      <div className="column is-6 cta__left">
        <h3>{title}</h3>
        <p>{text}</p>
        <Button className="has-arrow" href={button?.url}>
          {button?.text}
        </Button>
      </div>
      <div className="column is-6 cta__right">
        <Image src={image.url} alt={image.alt} />
      </div>
    </div>
  )
}

export default Cta
